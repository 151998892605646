import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO />
      <h1>
        <FormattedMessage id="404.title" />
      </h1>
      <p>
        <FormattedMessage id="404.message" />
      </p>
    </Layout>
  );
};

export default NotFoundPage;
